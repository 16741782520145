<template>
    <Modal :contains-floating-button="true">
        <h1>Stil et spørgsmål</h1>
        <Form @submit="create" :processing="processing">
            <fieldset>
                <Input type="text" placeholder="Emne*" :required="true" v-model="fields.subject"/>
                <Textarea placeholder="Spørgsmål*" :required="true" v-model="fields.message"/>
                <Upload label="Vedhæft billede"
                        tooltip="Vedhæft et billede som understøttelse til dit spørgsmål"
                        v-model="fields.file"/>
            </fieldset>
            <ActionButton icon="check" :float="true"/>
        </Form>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Upload from '@/components/inputs/Upload.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import Form from '@/components/Form.vue';
import Input from '@/components/inputs/Input.vue';
import Textarea from '@/components/inputs/Textarea.vue';
import { httpsRequest } from '@/utils/firebase';
import useState from '@/store';

export default {
    name: 'Question',
    components: {
        Modal,
        Upload,
        ActionButton,
        Form,
        Input,
        Textarea,
    },
    setup() {
        const { user, project } = useState();
        return { user, project };
    },
    data() {
        return {
            fields: {
                subject: null,
                message: null,
                file: null,
            },
            processing: false,
        };
    },
    methods: {
        async create() {
            this.processing = true;
            console.log('creating');

            try {
                const data = {
                    request: {
                        subject: this.fields.subject,
                        comment: {
                            body: this.fields.message,
                        },
                        requester: {
                            name: this.user.name,
                            email: this.user.email,
                        },
                        custom_fields: [{ id: 360021588518, value: this.project.id }],
                    },
                };
                if (this.fields.file) {
                    let attachment = await fetch(`https://selvbyggerplus.zendesk.com/api/v2/uploads.json?filename=${ this.fields.file.name }`, {
                        method: 'POST',
                        headers: { 'Content-type': 'application/binary' },
                        body: this.fields.file,
                    });

                    attachment = await attachment.json();
                    data.request.comment.uploads = [attachment.upload.token];
                }

                const createZendeskRequest = await httpsRequest.httpsCallable('createZendeskTicket');
                const response = await createZendeskRequest(data);
                const { request } = response.data.result;

                await this.$router.push({
                    name: 'questions',
                    params: { ...this.buildTicketObject(request) },
                });
            } catch (err) {
                console.log(err);
            }

            this.processing = false;
        },
        buildTicketObject(request) {
            return {
                id: request.id,
                subject: request.subject,
                description: request.description,
                created_at: request.created_at,
                status: request.status,
            };
        },
    },
};
</script>
